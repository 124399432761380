<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
                <Toolbar class="pl-0 pr-0">
					<template v-slot:start> 
                        <Button v-if="checkPermission(moduleId,'insert')" v-tooltip.top="'Add New'" :label="showBtnLabels ? 'Add New' : ''" @click="loadForm" icon="pi pi-plus" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-primary mr-2" /> 
                        <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Refresh'" :label="showBtnLabels ? 'Refresh' : ''" icon="pi pi-refresh" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-success mr-2"   @click="getRecords" /> 
                        <!-- <Button v-if="checkPermission(moduleId,'export')" v-tooltip.top="'Export'" icon="pi pi-external-link" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-warning mr-2" :label="showBtnLabels ? 'Export' : ''" @click="exportCSV($event)" /> -->
                        <!-- <Button v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Remove Filters'" type="button" icon="pi pi-filter-slash" :label="showBtnLabels ? 'Remove Filters' : ''" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-info mr-2"  @click="clearFilter()"/>  -->
                        <!-- <Button v-if="checkPermission(moduleId,'delete')" v-tooltip.top="'Delete Selected'" :label="showBtnLabels ? 'Delete Selected' : ''" icon="pi pi-trash" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-danger mr-2"  @click="deleteRecord" :disabled="!selectedRecords || !selectedRecords.length" /> -->
					</template> 
                    <template v-slot:end> 
                        <Button  v-if="checkPermission(moduleId,'view')" v-tooltip.top="'Helping Material'" :label="showBtnLabels ? 'Help' : ''" @click="showHelp = true" icon="pi pi-question" :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'],[toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]" class="p-button-raised p-button-info mr-2"  /> 
                    </template> 
				</Toolbar> 
                <Divider />
                <FullCalendar :events="events" :options="options" />
				<CalendarForm :dateFormat="calendarFormat()" :hourFormat="userData['company_time_format'] ? userData['company_time_format'] : 24"  v-on:formClose="closeForm" v-if="showForm  && (checkPermission(moduleId,'insert') || checkPermission(moduleId,'update'))" :selectedDate="selectedDate" :recordId="selectedRecordId" :showForm="true" /> 
				<ModuleHelp v-on:closeHelp="showHelp =false" v-if="showHelp  && checkPermission(moduleId,'view')" :moduleId="moduleId"/>
			</div>
        </div>
    </div>
	<Toast />
    <ConfirmDialog group="dialog" />
</template> 
<script> 

import CalendarForm from '../components/CalendarForm.vue';
import ModuleHelp from '../components/Help/ModuleHelp.vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
export default {
	data() {
		return {
            moduleId:50,
			selectedDate:'',
			eventDialog: false,
			clickedEvent: null, 
			changedEvent: {title:'', start: null, end:'', allDay: null},
			options: {
                plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
                initialDate : new Date().toJSON().slice(0,10).replace(/-/g,'-'), // today date in format YYYY-MM-DD
                headerToolbar: {
                    left: 'prev,next',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                },
                editable: true,
                selectable:true, 
                selectMirror: true, 
                dayMaxEvents: true,
				dateClick: (e) => {
					this.selectedDate = e.dateStr;
					this.showForm = true;

				},
				eventClick: (e) => {
                    // this.eventDialog = true;
                    // this.clickedEvent = e.event;
					console.log(e.event);
					this.selectedRecordId = e.event.id;
					this.showForm = true;
					this.addViewInURL({view: 'add-calendar', id:this.selectedRecordId}); 
                    // this.changedEvent.title = this.clickedEvent.title;
                    // this.changedEvent.start = this.clickedEvent.start;
                    // this.changedEvent.end = this.clickedEvent.end;
                }
            },
            events: [], 
			module_name:'Calendar Events', 
			actions: [],  
			showHelp : false, 
			isSaving:false, 
			records: null, 
			filters: null, 
			loading: true,  
			selectedRecords: null, 
			showForm:false,
			selectedRecordId: 0,  
			saveAndClose:false, 
		};
	},
	eventService: null,
	components:{
		CalendarForm,
		ModuleHelp 
	},
	created() { 
		if(this.checkPermission(this.moduleId,'update')){
			this.actions.push({
				label: 'Update',
				icon: 'pi pi-refresh',
				command: () => {
					this.getClickedRecord('update');
				}
			});
		}
		if(this.checkPermission(this.moduleId,'delete')){
			this.actions.push({
				label: 'Delete',
				icon: 'pi pi-times',
				command: () => {
					this.getClickedRecord('delete');
				}
			});
		}   
	},
	mounted() {
		this.getRecords();
		if(this.$route.query.view){
			this.urlParams = JSON.parse(this.$CryptoJS.AES.decrypt(this.$route.query.view, this.encryptKey).toString(this.$CryptoJS.enc.Utf8));
			if(Object.keys(this.urlParams).length){
				this.urlParams.forEach( (parameter) => {
					if(parameter.view == 'add-calendar'){
						this.selectedRecordId = parameter.id;
						this.showForm = true;
					}  
				})
			}
		}
	},
	methods: {
		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.events.length; i++) {
				if (this.events[i].id === id) {
					index = i;
					break;
				}
			}
			return index;
		}, 
		getClickedRecord(action){
			if(action == 'delete'){ 
				this.deleteRecord(); 
			}
			else if(action == 'view'){
				this.viewRecord();
			}  
			else if(action == 'update'){
				this.addViewInURL({view: 'add-calendar', id:this.selectedRecordId}); 
				this.showForm = true; 
			}
		},
		toggleRecordMenu(event) {
			this.selectedRecordId = event.currentTarget.getAttribute('recordId');  
			this.$refs.menu.toggle(event);
		},
		loadForm(){ 
			this.selectedRecordId = 0;
			this.showForm = true;
			this.addViewInURL({view: 'add-calendar', id:this.selectedRecordId}); 
		},
		closeForm(){ 
			if(!this.selectedRecordId){
				this.getRecords();
			}
			this.removeViewFromURL('add-calendar');
			this.showForm = false;
		}, 
		getRecords(){ 
			var Vthis = this;
			this.loading = true;
			this.axios.post('getAllCalendarEvents')
			.then( (response) => {  
				let responseStatus = this.printResponseResult(response,false); 
                if(responseStatus == 200){
					this.records = response.data;  
					if(this.records.length){
						Vthis.events = [];
						this.records.forEach( (record) => { 
							Vthis.events.push({
								'id' : record.id,
								'title': record.name,
								'start': record.start_time,
								'end': record.end_time
							});
						})
					}
					Vthis.events = JSON.parse(JSON.stringify(Vthis.events)); 
					this.loading = false; 
				}
			})
			.catch((error) => {    
				this.printResponseResult(error);
			})
			.finally(() => { 
			})  
		}, 
		viewRecord(){ 
			var Vthis = this;
			this.loading = true;
			this.axios.post('getAllProducts',{id:this.selectedRecordId})
			.then( (response) => {  
				this.printResponseResult(response,false);  
			})
			.catch((error) => {   
				this.printResponseResult(error);
			})
			.finally(() => { 
			})  
		},
		 
		onToggle(value) {
			this.selectedColumns = this.columns.filter(col => value.includes(col));
		}, 
		isRTL() {
			return this.$appState.RTL;
		} 
	}
}
</script>

<style scoped>
@media screen and (max-width: 960px) {
    ::v-deep(.fc-header-toolbar) {
        display: flex;
        flex-wrap: wrap;
    }
}

::v-deep(.fc .fc-col-header-cell-cushion),
::v-deep(.fc-daygrid-dot-event .fc-event-time),
::v-deep(.fc-daygrid-dot-event .fc-event-title),
::v-deep(.fc .fc-daygrid-day-number),
::v-deep(.fc .fc-daygrid-more-link) {
	color: var(--text-color);
}

::v-deep(.fc.fc-theme-standard .fc-highlight) {
	background: var(--fc-highlight-color, rgba(188, 232, 241, 0.3));;
}
</style>
